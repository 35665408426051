@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700&display=swap);
body {
  margin: 0;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

/* :root {
  --primaryFG: #ed254e;
  --secondaryFG: #ffffff;
  --primaryBG: #060606;
  --secondaryBG: #242526;
} */

:root {
  --primaryFG: white;
  --secondaryFG: #fa0404;
  --secondaryFG-light: #f76060;
  --primaryBG: #111111;
  --primaryBG-light: #383838;

  --topNavHeight: 15vh;
}


.Nav_nav__3Bj6t {
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 80px;
  letter-spacing: 0.1em;
  font-size: 1.5em;
}

.Nav_left__IXhcw {
  display: flex;
  align-items: center;
  padding: 15px 0;
  justify-content: space-between;
  width: 30vw;
}

.Nav_ring__ApSMY {
  fill: var(--primaryFG);
  width: 2em;
  height: 2em;
}

.Nav_left__IXhcw > a {
  text-decoration: none;
  color: var(--primaryFG);
  margin-left: 15px;
}

.Nav_right__yqfXw {
  display: flex;
  align-items: center;
}

.Nav_toggle__DyEl- {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: var(--primaryFG);
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  height: 3em;
  width: 3em;
  margin-right: 3em;
}

.Nav_toggle__DyEl-:hover {
  background: var(--primaryBG-light);
}

.Nav_themeIcon__3B2_G {
  height: 2em;
}

.Nav_name__2VBIP {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--secondaryFG);
  font-size: 2em;
}

.Nav_last__24Tbh {
  font-weight: 700;
}

@media only screen and (max-width: 1300px) {
  .Nav_nav__3Bj6t {
    font-size: 1em;
  }
}

@media only screen and (max-width: 800px) {
  .Nav_nav__3Bj6t {
    padding: 30px 10px;
  }
  .Nav_left__IXhcw {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
    justify-content: space-between;
    width: auto;
    height: 18vh;
  }
  .Nav_left__IXhcw > a {
    margin: 15px 0 0 0;
  }
  .Nav_right__yqfXw {
    flex-direction: column;
    align-items: flex-end;
  }
  .Nav_toggle__DyEl- {
    margin: 0 0 2em 0;
  }
}

.Footer_footer__3kRR3 {
  position: absolute;
  bottom: 30px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 30px 80px;
  letter-spacing: 0.1em;
  font-size: 1.7em;
}

.Footer_left__38yYI {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.Footer_location__2LzPo {
  display: flex;
  width: 100%;
}

.Footer_city__w7IT0 {
  margin-left: 15px;
  color: var(--secondaryFG);
  font-weight: 700;
}

.Footer_right__2o1OT {
  display: flex;
  /* width: 30vw; */
  align-items: center;
}

.Footer_socialLink__1DqkT {
  text-decoration: none;
}

.Footer_socialIcon__pSam4 {
  color: var(--primaryFG);
  height: 1.5em;
  margin-left: 3em;
}

@media only screen and (max-width: 1300px) {
  .Footer_footer__3kRR3 {
    font-size: 1em;
  }
}

@media only screen and (max-width: 800px) {
  .Footer_footer__3kRR3 {
    bottom: 45vh;
    width: 100vw;
    padding: 0 30px;
    align-items: flex-start;
  }
  .Footer_left__38yYI {
    font-size: 1.2em;
  }
  .Footer_right__2o1OT {
    flex-direction: column;
  }
  .Footer_socialIcon__pSam4 {
    margin-left: 0;
    margin-top: 1.5em;
  }
}

.Home_Home__13bqR {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.Home_me__3UNzn {
  width: 800px;
  max-width: 50vw;
}

@media only screen and (max-width: 800px) {
  .Home_Home__13bqR {
    height: calc(100vh - 50px);
  }
  .Home_me__3UNzn {
    position: absolute;
    bottom: 0;
    width: auto;
    height: 500px;
    max-height: 50vh;
    max-width: unset;
  }
}

.ProjectCard_card__3t4HK {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0;
}

.ProjectCard_left__30dlJ {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 30px;
}

.ProjectCard_title__Uk9EL {
  margin: 0;
  font-size: 2.2em;
  /* font-weight: 500; */
  color: var(--secondaryFG-light);
}

.ProjectCard_description__1LFkU {
  margin: 15px 0;
  font-size: 1.2em;
}

.ProjectCard_icon__1brE8 {
  height: 1.8em;
  margin: 0 1em;
}

.ProjectCard_right__dorY0 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--primaryFG);
}

.ProjectCard_image__ZIytz {
  width: 90%;
  border-radius: 15px;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.ProjectCard_right__dorY0:hover .ProjectCard_image__ZIytz {
  opacity: 0.6;
}

.ProjectCard_open__1XhHn {
  position: absolute;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.ProjectCard_right__dorY0:hover .ProjectCard_open__1XhHn {
  opacity: 1;
}

@media only screen and (max-width: 800px) {
  .ProjectCard_card__3t4HK {
    flex-direction: column-reverse;
  }
  .ProjectCard_left__30dlJ,
  .ProjectCard_right__dorY0 {
    width: unset;
  }
}

.GitHubCard_card__1Ai7p {
  width: 350px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  background: var(--primaryFG);
  color: var(--primaryBG);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  margin: 1.5em;
}

.GitHubCard_url__3cFti {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 2px var(--secondaryFG-light);
  box-sizing: border-box;
  padding: 5px 15px;
  width: 100%;
}

.GitHubCard_heading__1QDa3 {
  margin: 0;
  color: var(--primaryBG);
}

.GitHubCard_icon__22MB7 {
  height: 2em;
  margin-left: 1em;
  /* display: none; */
}

.GitHubCard_description__HdpNF {
  margin: 5px 0;
  text-align: center;
  width: 100%;
}

.GitHubCard_links__1040L {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.GitHubCard_lnkSpan__Ce20s {
  display: flex;
  align-items: center;
  color: var(--primaryBG);
}

.GitHubCard_lnkIcon__2ifOh {
  height: 1em;
  margin-right: 0.2em;
  fill: var(--primaryBG);
}


.Projects_projects__38wue {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--topNavHeight) 0;
}

.Projects_projectHeading__2dqQ9 {
  font-size: 3.5em;
  margin: 0;
  margin-bottom: 4vh;
}

.Projects_githubProjects__2TRPu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--secondaryFG-light);
  box-sizing: border-box;
  padding: 4em 1em;
}

.Projects_githubHeading__39CE- {
  font-size: 3.5em;
  margin-bottom: 4vh 0;
}

.Projects_githubCards__1KWgz {
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .Projects_projects__38wue {
    margin: 30vh 0 10vh 0;
  }
  .Projects_projectHeading__2dqQ9,
  .Projects_githubHeading__39CE- {
    font-size: 2.5em;
  }
}

.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background: var(--primaryBG);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: var(--primaryFG);
  -webkit-transition: color, background;
  transition: color, background;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}

[data-theme="light"] {
  --primaryFG: #111111;
  --primaryBG: #f2f2f2;
  --primaryBG-light: #cccccc;
}


