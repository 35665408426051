@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700&display=swap');

body {
  margin: 0;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

/* :root {
  --primaryFG: #ed254e;
  --secondaryFG: #ffffff;
  --primaryBG: #060606;
  --secondaryBG: #242526;
} */

:root {
  --primaryFG: white;
  --secondaryFG: #fa0404;
  --secondaryFG-light: #f76060;
  --primaryBG: #111111;
  --primaryBG-light: #383838;

  --topNavHeight: 15vh;
}

