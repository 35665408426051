.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background: var(--primaryBG);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: var(--primaryFG);
  transition: color, background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

[data-theme="light"] {
  --primaryFG: #111111;
  --primaryBG: #f2f2f2;
  --primaryBG-light: #cccccc;
}

