.footer {
  position: absolute;
  bottom: 30px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 30px 80px;
  letter-spacing: 0.1em;
  font-size: 1.7em;
}

.left {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.location {
  display: flex;
  width: 100%;
}

.city {
  margin-left: 15px;
  color: var(--secondaryFG);
  font-weight: 700;
}

.right {
  display: flex;
  /* width: 30vw; */
  align-items: center;
}

.socialLink {
  text-decoration: none;
}

.socialIcon {
  color: var(--primaryFG);
  height: 1.5em;
  margin-left: 3em;
}

@media only screen and (max-width: 1300px) {
  .footer {
    font-size: 1em;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    bottom: 45vh;
    width: 100vw;
    padding: 0 30px;
    align-items: flex-start;
  }
  .left {
    font-size: 1.2em;
  }
  .right {
    flex-direction: column;
  }
  .socialIcon {
    margin-left: 0;
    margin-top: 1.5em;
  }
}
