.nav {
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 80px;
  letter-spacing: 0.1em;
  font-size: 1.5em;
}

.left {
  display: flex;
  align-items: center;
  padding: 15px 0;
  justify-content: space-between;
  width: 30vw;
}

.ring {
  fill: var(--primaryFG);
  width: 2em;
  height: 2em;
}

.left > a {
  text-decoration: none;
  color: var(--primaryFG);
  margin-left: 15px;
}

.right {
  display: flex;
  align-items: center;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: var(--primaryFG);
  transition: all 0.2s ease-in;
  height: 3em;
  width: 3em;
  margin-right: 3em;
}

.toggle:hover {
  background: var(--primaryBG-light);
}

.themeIcon {
  height: 2em;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--secondaryFG);
  font-size: 2em;
}

.last {
  font-weight: 700;
}

@media only screen and (max-width: 1300px) {
  .nav {
    font-size: 1em;
  }
}

@media only screen and (max-width: 800px) {
  .nav {
    padding: 30px 10px;
  }
  .left {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
    justify-content: space-between;
    width: auto;
    height: 18vh;
  }
  .left > a {
    margin: 15px 0 0 0;
  }
  .right {
    flex-direction: column;
    align-items: flex-end;
  }
  .toggle {
    margin: 0 0 2em 0;
  }
}
