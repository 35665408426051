.Home {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.me {
  width: 800px;
  max-width: 50vw;
}

@media only screen and (max-width: 800px) {
  .Home {
    height: calc(100vh - 50px);
  }
  .me {
    position: absolute;
    bottom: 0;
    width: auto;
    height: 500px;
    max-height: 50vh;
    max-width: unset;
  }
}
