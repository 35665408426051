.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--topNavHeight) 0;
}

.projectHeading {
  font-size: 3.5em;
  margin: 0;
  margin-bottom: 4vh;
}

.githubProjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--secondaryFG-light);
  box-sizing: border-box;
  padding: 4em 1em;
}

.githubHeading {
  font-size: 3.5em;
  margin-bottom: 4vh 0;
}

.githubCards {
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .projects {
    margin: 30vh 0 10vh 0;
  }
  .projectHeading,
  .githubHeading {
    font-size: 2.5em;
  }
}
